export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: false,
  apiUrl: 'https://api.fcthighcourt.gov.ng/api',
  courtTitle: 'HIGH COURT OF JUSTICE',
  courtTitleSentence: 'Federal High Court of Nigeria',
  stateTitle: 'FCT, Abuja',
  stateTitleSentence: 'Federal Capital Territory Abuja',
  currentDivision: 'Abuja Judicial Division',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://api.fcthighcourt.gov.ng/api',
  BASE_URL_II: 'https://api.fcthighcourt.gov.ng/api',
  API_URL: '/',
  PORTAL_URL: 'https://portal.fcthighcourt.gov.ng',
  currentState: 'FCT, Abuja',
  court_bg_path: 'assets/img/court-bg.jpg',
  court_logo_path: 'assets/img/ecms-light-logo.png',
  paystack_public_key: 'pk_test_0e8e87eb3fdf39c788648eefeb54063a3c293a3d',
  // remitta_api_key: 'RkhDfDEzOTMwMjY1MzYyfDczOTdmMTIyZTJiYWFhNDUxYTUwNjJmOTkxYTBmOTlhOWJiOGY2OGRmMDVhNWE1MTYyZmI5MjRlYTllYTE1ZjcyYzQzMDRiNTJmMDhlMTdiNWY0ZGY1NDgyNTE0MjRlNTI5NWZiM2UxZDZmNzBjM2E5ZDQ0ZWFiOTg4ZGFiNTA4',
  // remitta_mode: 'live',
  remitta_api_key: 'QzAwMDAyNTE5MzV8NTcwODYyNDl8NTk0ZGFkZTE2OWM3N2UyZTMyMzlhYWJiZjM4NjdiZThhYWQyMTUxMWVlMjNiMjdjYmFmODVlNTZlMDFlNTA5ZGZjNDVhOTliM2Q2OGNhZmE0YmI3YzllODBhMTdmNmIxMDc4ZWRlMWM5MDc1OTc4ZGYxMDQ5ZjIxYWU2Mjc0YjQ=',
  remitta_mode: 'test',
  interswitch_mechant_code: 'VNA',
  // interswitch_mechant_code: 'MX128354',
  interswitch_pay_item: '103',
  // interswitch_pay_item: 'Default_Payable_MX128354',
  // interswitch_mode: 'LIVE',
  interswitch_mode: 'TEST',
  currentCourtInfoEmail: 'info@fhc.gov.ng'
};
