<!-- Landin Content -->
<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Knowledge base Jumbotron -->
    <section class="mt-1" id="knowledge-base-search">
      <div class="row">
        <div class="col-12">
          <div style="
              background-image: url('{{court_bg_path}}');
              background-size: cover;
              background-position: 100% 100%;
            " class="card knowledge-base-bg text-center green-gradient shadow-sm">
            <div class="card-body">
              <div class="glass py-3 shadow-sm">
                <img src="{{court_logo_path}}" alt="brand-logo" width="150" class="img-fit" />
                <h1 class="mt-1 text-white">{{currentState}} Judiciary</h1>
                <h4 class="text-primary mt-1 text-white px-3">
                  <p>Welcome to the Official Probate and Process Portal of {{currentState}}</p>
                  <p> Please use the links below for more information</p>
                </h4>
                <ul class="nav justify-content-center mt-1">
                  <li class="nav-item">
                    <button routerLink="/auth/login" type="button" class="btn btn-relief-primary mr-25" rippleEffect>
                      <span [data-feather]="'log-in'" [class]="'mr-25'"></span>Login
                    </button>
                  </li>
                  <li class="nav-item">
                    <button routerLink="/auth/register" type="button" class="btn btn-relief-primary" rippleEffect>
                      <span [data-feather]="'user-plus'" [class]="'mr-25'"></span>Register
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/ Knowledge base Jumbotron -->

    <!-- Knowledge base -->
    <section id="knowledge-base-content">
      <div class="row kb-search-content-info match-height">
        <div class="col-md-4 col-sm-6">
          <div class="card">
            <a>
              <img src="../../../assets/img/undraw_Online_learning_re_qw08.png" class="card-img-top img-fit"
                alt="knowledge-base-image" height="350" />
              <div class="card-body text-center">
                <h4 class="text-primary">E-Affidavit</h4>
                <p class="text-body mt-1 mb-0">
                  Written Statement in which Deponent swears oath or affirms
                  his/her Statement.
                  <!--                   
                      Just like earlier people used to take oath
                      in front of God OR also we can consider the example of Courts
                      b... -->
                </p>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-12 kb-search-content">
          <div class="card">
            <a>
              <img src="../../../assets/img/undraw_Online_articles_re_yrkj.png" class="card-img-top img-fit"
                alt="knowledge-base-image" height="350" />
              <div class="card-body text-center">
                <h4 class="text-primary">E-Verification</h4>
                <p class="text-body mt-1 mb-0">
                  E-Verification allows you to verify all affidavits quickly and securely online, ensuring authenticity
                  and compliance with legal standards.
                </p>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-12 kb-search-content">
          <div class="card">
            <a>
              <img src="../../../assets/img/undraw_Meet_the_team_re_4h08.png" class="card-img-top img-fit"
                alt="knowledge-base-image" height="350" />
              <div class="card-body text-center">
                <h4 class="text-primary">Accredited Agents</h4>
                <p class="text-body mt-1 mb-0">
                  Accredited agents are individuals or organizations authorized to assist with submitting requests to
                  the court on behalf of a party.

                  <!-- Although accredited agents have the necessary qualifications and approvals, they are -->
                  <!-- not exclusive providers, meaning other authorized providers may also operate in the same area. -->
                </p>
                <!-- <button
                      type="button"
                      class="btn btn-gradient-primary mt-1 round"
                      (click)="modalOpenVC(modalVC)"
                      rippleEffect
                    >
                      Learn More
                    </button> -->
              </div>
            </a>
          </div>
        </div>

        <!-- no result -->
        <ng-container></ng-container>
        <ng-template #noResults>
          <div class="col-12 text-center no-result">
            <h4 class="mt-4">Search result not found!!</h4>
          </div>
        </ng-template>
      </div>
    </section>
    <!-- Knowledge base ends -->
  </div>
</div>

<!-- Landin Content -->

<!-- Modal -->
<ng-template #modalVC let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
      Vertically Centered
    </h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <p>
      Chocolate bar jelly dragée cupcake chocolate bar I love donut liquorice.
      Powder I love marzipan donut candy canes jelly-o. Dragée liquorice apple
      pie candy biscuit danish lemon drops sugar plum.
    </p>
    <ngb-alert [type]="'success'" [dismissible]="false">
      <div class="alert-body">
        <span class="font-weight-bolder">Well done!</span> You successfully read
        this important alert message.
      </div>
    </ngb-alert>

    <iframe id="Geeks3" width="750" height="350" src="https://www.youtube.com/embed/V5he1JXiQbg?autoplay=1"
      frameborder="0" allowfullscreen>
    </iframe>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Accept click')" rippleEffect>
      Close
    </button>
  </div>
</ng-template>
<!-- / Modal -->

<!-- Footer -->
<footer></footer>
<!-- Footer -->